<template>
  <div class="cont-signup-step">
    <div class="subtitle-signup">Verified account</div>
    <div class="title-signup">Let's start by you name</div>
    <div class="label">
      <p>
        <span>Please fil in the details below so that we can know you better.</span>
      </p>
    </div>
    <div class="cont-form">
      <div class="inner-form">
        <div v-if="!loading" class="cont-elem-profile elem-form">
          <input class="v-select no-selectable" placeholder="Email" readonly v-model="email" />
        </div>
        <div v-if="!loading" class="cont-elem-profile elem-form">
          <input class="v-select" placeholder="First name" v-model="firstName" />
        </div>
        <div v-if="!loading" class="cont-elem-profile elem-form">
          <input class="v-select" placeholder="Last name" v-model="lastName" />
        </div>
        <div v-if="!loading" class="cont-elem-profile elem-form">
          <input class="v-select" placeholder="City" v-model="city" />
        </div>
      </div>
      <div class="cont-btn">
        <div class="btn-green greener radius" @click="update">Submit</div>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Loading from '@/components/utilityPartials/_loading.vue';

export default {
  name: 'ProfileBuild',
  components: {
    Loading,
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      city: '',
      loading: true,
    };
  },
  computed: mapState('user', {
    email: (state) => state.email,
  }),
  methods: {
    update() {
      // update city
      this.$api.post('auth/profile', { name: { firstName: this.firstName, lastName: this.lastName }, location: this.city }).then(() => {
        this.$store.dispatch('auth/setProfileCreated');
      });
    },
  },
  mounted() {
    this.$api.get('auth/profile').then((res) => {
      if (res.data.name) {
        this.firstName = res.data.name.firstName || '';
        this.lastName = res.data.name.lastName || '';
      }
      this.loading = false;
    });
    this.$store.dispatch('auth/getTokenInfo').then((token) => {
      if (token.given_name) {
        this.firstName = token.given_name;
      }
      if (token.family_name) {
        this.lastName = token.family_name;
      }
    });
  },

};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.cont-form{
  margin-top: 2vh;
  height: auto;
}
// .cont-signup-step{
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   justify-content: center;
//   flex-wrap: wrap;
//   .cont-form{
//     margin-top: 30px;
//     width: 100%;
//     .cont-elem-profile{
//       width: 100%;
//       margin-top: 10px;
//       .v-select{
//         width: 100%;
//         border: 2px solid $logo_color;
//         border-radius: 10px;
//         padding: 8px 8px;
//         &:read-only{
//           pointer-events: none;
//           cursor: unset;
//         }
//       }
//       &:first-child{
//         margin-top: 0;
//       }
//     }
//   }
// }
</style>
