<template>
  <div>
    <loading v-if="loading" />
    <div v-else>
      <div v-for="invite in invites" :key="invite.id">
        <!-- {{invite}} <br> -->
        You've been invited by the company: {{invite.company}} <br>
        Role: {{invite.role.name}}
        <div class="btn-green radius" @click="acceptInvite(invite.id)" >Accept</div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/utilityPartials/_loading.vue';

export default {
  name: 'Invite',
  props: ['acceptedInviteCallback'],
  components: {
    Loading,
  },
  data() {
    return {
      loading: true,
      invites: [],
    };
  },
  methods: {
    acceptInvite(inviteId) {
      this.$api.post(`auth/invites/${inviteId}`).then((res) => {
        this.acceptedInviteCallback(res.data);
      });
    },
  },
  mounted() {
    this.$api.get('auth/invites').then((res) => {
      this.invites = res.data;
      this.loading = false;
      console.log(res.data);
      if (res.data.length === 0) {
        this.$emit('empty-invites');
      }
    });
  },
};
</script>

<style>

</style>
