<template>
  <div style="height:100%;">
    <talent-info v-if="signupIndex < 4 && signupIndex > -1" />
    <talent-resume v-if="signupIndex > 3 && signupIndex < 7"   />
    <confirm-resume v-if="signupIndex === 7" />
  </div>
</template>

<script>

import TalentResume from './TalentResume.vue';
import TalentInfo from './TalentInfo.vue';
import ConfirmResume from './ConfirmResume.vue';

export default {
  name: 'TalentSignup',
  components: {
    TalentResume,
    TalentInfo,
    ConfirmResume,
  },
  data() {
    return {
      returnFrom: false,
    };
  },
  computed: {
    signupIndex() {
      return this.$store.state.signupIndex;
    },
  },
  mounted() {
    this.$store.dispatch('talent/getTalentInfo').then(() => { this.$store.dispatch('signupIndex', 0); });
    this.$store.dispatch('talent/getResume');
  },
  watch: {
    signupIndex(newVal) {
      if (newVal > 7) {
        this.$store.dispatch('user/getUserInfo').then(() => {
          this.$router.push('/');
        });
      }
    },
  },

};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
  .cont-submit{
    .submit{
      &.btn-green{
        background-color: $logo_color;
        color: #ffffff;
        font-size: $classic_fs;
      }
    }
  }
</style>
