<template>
  <div class="cont-signup-step">

    <div class="subtitle-signup">Background informations</div>
    <!-- sub index 0 -->
    <div class="title-signup" v-if="currentField === 'academicBG'" >
      Please tell us about your academic background
    </div>
    <div class="title-signup" v-if="currentField === 'professionalBG'" >
      Please tell us about your professional background
    </div>
    <div class="title-signup" v-if="currentField === 'projects'" >
      Please tell us about your projects
    </div>
    <div class="label">
      <p>
        <span
          v-if="currentField === 'academicBG' ||
          currentField === 'professionalBG' ||
          currentField === 'projects'"
        >
            Please fill in the details below so that we can know you better.
        </span>
      </p>
    </div>
    <!-- <h2>{{currentFieldTitle}}</h2> -->
    <div class="cont-form" >
      <div class="inner-form-resume"  id="resumeCont" >
        <div  class="cont-entry-signup">
          <resume-entry
            v-for="(entry, index) in getCurrentList"
            :key="entry._id"
            :objectData="entry"
            :index="index"
            :editable="true"
            :type="currentField"
          />
          <div class="cont-btn">
            <div class="btn-green radius-small greener pluseur"
              v-if="addNewBool" @click="() => addNew(currentField)"
            >
              +
            </div>
          </div>
        </div>
      </div>
      <div class="cont-btn double">
        <div class="radius btn-green" @click="goLast">Back</div>
        <div class="radius btn-green" @click="goNextResume" v-if="!updating">
          {{skippedBool? 'Skip':'Next'}}
        </div>
      </div>
    </div>
    <!-- <div class="cont-btn">
      <div class="radius btn-green" @click="goNextResume" v-if="!updating">
        {{skippedBool? 'Skip':'Next'}}
      </div>
      <div class="radius btn-green" @click="goLast">Back</div>
    </div> -->
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import ResumeEntry from '@/components/TalentComponents/resume/ResumeEntry.vue';

export default {
  name: 'TalentResume',
  components: {
    ResumeEntry,
  },
  props: ['goTo'],
  data() {
    return {
      updating: false,
    };
  },
  computed: {
    currentField() {
      return ['academicBG', 'professionalBG', 'projects', 'finished', ''][this.resumeIndex];
    },
    currentFieldTitle() {
      return ['Academic Background', 'Professional Background', 'Personal Projects', 'Resume Complete!', ''][this.resumeIndex];
    },
    getCurrentList() {
      const curList = this.$store.getters[`talent/${this.currentField}`];
      return curList.sort((a, b) => new Date(b.dates.start) - new Date(a.dates.start));
    },
    addNewBool() {
      if (this.resumeIndex >= 3) {
        return false;
      }
      const currentEntries = this.$store.getters[`talent/${this.currentField}`];

      if (currentEntries.lengt === 0 || !!currentEntries[currentEntries.length - 1]._id) {
        return true;
      }
      return false;
    },
    skippedBool() {
      try {
        return this.$store.getters[`talent/${this.currentField}`].filter((entry) => !!entry._id).length === 0;
      } catch (e) {
        return false;
      }
    },
    resumeIndex() {
      return this.$store.state.signupIndex - 4;
    },
  },
  methods: {
    goNextResume() {
      this.$store.dispatch('signupIndex', this.$store.state.signupIndex + 1);
      if (this.resumeIndex < 3 && this.getCurrentList.length === 0) {
        this.$store.dispatch('talent/addBlankResume', this.currentField);
      }
    },
    addNew(type) {
      this.$store.dispatch('talent/addBlankResume', type);
    },
    goLast() {
      this.$store.dispatch('signupIndex', this.$store.state.signupIndex - 1);
    },
  },
  mounted() {
    if (this.$store.getters['talent/academicBG'].length === 0) {
      this.$store.dispatch('talent/addBlankResume', 'academicBG');
    }
    setTimeout(() => {
      const psSteps = new PerfectScrollbar('#resumeCont', {
        wheelPropagation: false,
        wheelSpeed: 1,
      });
      psSteps.update();
    }, 100);
  },

};
</script>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";
  .talent-resume-signup{
    .cont-entry-signup{
      width: 100%;
    }
  }
  .cont-form{
    .ps__rail-y{
      display: none;
    }
  }
  .cont-btn-profile{
    margin-top: 2vh;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4vh;
  }
</style>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";

  .cont-btn-profile{
    margin-top: 2vh;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }
</style>
