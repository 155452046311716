<template>
  <div class="verify">
    <div v-if="!this.token">
      <div class="subtitle-signup">Verified account</div>
      <div class="title-signup">Please verify your email </div>
      <div class="label">
        <p v-if="loggedIn && !sentMail">
          <span>An email was sent to {{email || 'your email'}} </span>
          <a v-if="!sentMail" href="#" @click="resendVerify">click here to resend the email</a>
        </p>
        <p v-if="sentMail">
          <span>Email sent!</span>
        </p>
      </div>
      <div class="picto-verify-email">
        <img :src="require('@/assets/signup/picto_verify_email.svg')">
      </div>
    </div>
    <div v-if="this.token">

      Verifying token{{this.token}}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Verify',
  computed: mapState('auth', {
    loggedIn: (state) => state.loggedIn,
    email: (state) => state.email,
  }),
  props: ['token'],
  data() {
    return {
      sentMail: false,
    };
  },

  mounted() {
    if (this.token) {
      this.$api.post('auth/verify', { token: `${this.token}` }).then(() => {
        this.$store.dispatch('auth/setUserAttribute', { field: 'verified', val: true });
        this.$router.replace('/signup');
      }).catch((e) => this.handleError(e));
    }

    // POSSIBLE TO SKIP VERIFICATION IF USING 3rd PARTY APP
    // this.$store.dispatch('auth/getTokenInfo').then((token) => {
    //   if (token.email_verified) {
    //     this.$store.dispatch('auth/setUserAttribute', { field: 'verified', val: true });
    //   }
    // });
  },
  methods: {
    resendVerify() {
      this.$api.post('auth/resendVerify').then((res) => {
        if (res.status) {
          this.sentMail = true;
        }
      });
    },
    handleError(e) {
      throw new Error(e);
    },
  },
};
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/_variables.scss";
    .home{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .verify{
      h3{
        margin-top: 40px;
      }
      .picto-verify-email{
        width: 10vh;
      }
    }
</style>
