<template>
  <div class="signup-progress" id="signup-progress">
    <div class="subtitle-signup">Your resume</div>
    <div class="cont-info-resume-signup " id="list-info-resume-signup">
      <div class="markup-type radius-small">
        I'm a Talent
      </div>
      <div v-if="info" class="cont-elem-info-title-info-resume">
        <div class="title-info-resume">Your informations:</div>
        <div class="cont-info-resume radius box-shadow">
          <div class="name-user elem-info-resume">
            {{info.name.firstName}} {{info.name.lastName}}
          </div>
          <div class="email-user elem-info-resume">{{userData.email}}</div>
          <!-- {{info}} -->
        </div>
      </div>
      <div v-if="description" class="cont-elem-info-title-info-resume">
        <div class="title-info-resume wother">
          Your description:
          <div class="other">
            <div class="edit" @click="() => goToIndex(0)">
              edit
            </div>
          </div>
        </div>
        <div class="cont-info-resume radius box-shadow">
          <div class="description-user elem-info-resume">
            {{description}}
          </div>
          <!-- {{info}} -->
        </div>
      </div>
      <div v-if="skills" class="cont-elem-info-title-info-resume">
        <div class="title-info-resume wother">
          Your skills:
          <div class="other">
            <div class="edit" @click="() => goToIndex(1)">
              edit
            </div>
          </div>
        </div>
        <div class="cont-info-resume radius box-shadow">
          <div class="content_tags">
            <div class="inner_tag_list" id="tagslist">
              <div
                v-for="(skill, index) in skills"
                :key="index"
                class="elem_skill radius-small"
              >
                {{skill}}
              </div>
            </div>
            <div class="see-more" v-if="heightTagsOK" @click="handleOpenPanel($event)">
              <img :src="require('@/assets/arrow_list.svg')">
            </div>
          </div>
        </div>
      </div>
      <div v-if="technologies" class="cont-elem-info-title-info-resume">
        <div class="title-info-resume wother">
          Your technologies:
          <div class="other">
            <div class="edit" @click="() => goToIndex(2)">
              edit
            </div>
          </div>
        </div>
        <div class="cont-info-resume radius box-shadow">
          <div class="content_technos">
            <div class="inner_techno_list" id="technolist">
              <div
                class="elem-techno box-shadow radius-small"
                v-for="(techno, index) in technologies"
                :key="index"
              >
                <div
                  class="label"
                  v-tooltip.bottom="{
                  content: techno,
                  delay: {
                    show: 0,
                    hide: 0,
                  }
                }"
                >
                  <img
                    v-if="getPictoTechno(techno)"
                    :src="require(`@/assets/pictos/${getPictoTechno(techno)}.svg`)"
                  >
                  <img
                    v-else
                    :src="require(`@/assets/pictos/technos/unspecified.svg`)"
                  >
                </div>
              </div>
            </div>
            <div class="see-more" v-if="heightTechnoOK" @click="handleOpenPanel($event)">
              <img :src="require('@/assets/arrow_list.svg')">
            </div>
          </div>
        </div>
      </div>
      <!-- <div v-if="technologies" @click="() => goToIndex(2)">
        technologies:
        {{technologies}}
      </div> -->
      <!-- <div v-if="socials" @click="() => goToIndex(3)">
        {{socials}}
      </div> -->
      <div v-if="socials" class="cont-elem-info-title-info-resume">
        <div class="title-info-resume wother">
          Your websites:
          <div class="other">
            <div class="edit" @click="() => goToIndex(3)">
              edit
            </div>
          </div>
        </div>
        <div class="cont-info-resume radius box-shadow">
          <!-- {{socials}} -->
          <div class="elem-social radius-small"
            v-for="(initialValue, index) in socials"
            :key="index"
          >
            <div class="cont-label-elem">
              <div class="inner-label-elem">
                <div class="label-social-picto">
                  <img
                    v-if="initialValue.site === 'Facebook'"
                    :src="require('@/assets/pictosocial/social-facebook.svg')"
                  >
                  <img
                    v-else-if="initialValue.site === 'Instagram'"
                    :src="require('@/assets/pictosocial/social-instagram.svg')"
                  >
                  <img
                    v-else-if="initialValue.site === 'Twitter'"
                    :src="require('@/assets/pictosocial/social-twitter.svg')"
                  >
                  <img
                    v-else-if="initialValue.site === 'Linkedin'"
                    :src="require('@/assets/pictosocial/social-linkedin.svg')"
                  >
                  <img
                    v-else-if="initialValue.site === 'Website'"
                    :src="require('@/assets/pictosocial/social-web.svg')"
                  >
                  <div class="" v-else>
                    {{initialValue.site}}
                  </div>
                </div>
                <div class="elem">
                  {{initialValue.link}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div v-if="academicBG" @click="() => goToIndex(4)">
        {{academicBG}}
      </div> -->
      <div v-if="academicBG" class="cont-elem-info-title-info-resume">
        <div class="title-info-resume wother">
          Your Academic Background:
          <div class="other">
            <div class="edit" @click="() => goToIndex(4)">
              edit
            </div>
          </div>
        </div>
        <div class="cont-info-resume radius box-shadow">
          <div class="elem-bg radius-small"
            v-for="(bg, index) in academicBG"
            :key="index"
          >
            <div class="cont-label-elem">
              <div class="inner-label-elem">
                <div class="label-bg-date">
                  {{bg.dates.end? new Date(bg.dates.end).getFullYear():'Today' }}
                  <div class="separ"></div>
                  {{bg.dates.start? new Date(bg.dates.start).getFullYear(): '2020' }}
                </div>
                <div class="elem">
                  <h4>{{bg.diplomaType}} - {{bg.diplomaLabel}}</h4>
                  <h5>{{bg.establishment}}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="professionalBG" class="cont-elem-info-title-info-resume">
        <div class="title-info-resume wother">
          Your professionnal background:
          <div class="other">
            <div class="edit" @click="() => goToIndex(5)">
              edit
            </div>
          </div>
        </div>
        <div class="cont-info-resume radius box-shadow">
          <div class="elem-bg radius-small"
            v-for="(bg, index) in professionalBG"
            :key="index"
          >
            <div class="cont-label-elem">
              <div class="inner-label-elem">
                <div class="label-bg-date">
                  {{bg.dates.end? new Date(bg.dates.end).getFullYear():'Today' }}
                  <div class="separ"></div>
                  {{bg.dates.start? new Date(bg.dates.start).getFullYear(): '2020' }}
                </div>
                <div class="elem">
                  <h4>{{bg.company}} - {{bg.contract}}</h4>
                  <h5>{{bg.title}}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="projects" class="cont-elem-info-title-info-resume">
        <div class="title-info-resume wother">
          Your projects:
          <div class="other">
            <div class="edit" @click="() => goToIndex(6)">
              edit
            </div>
          </div>
        </div>
        <div class="cont-info-resume radius box-shadow">
          <div class="elem-bg radius-small"
            v-for="(bg, index) in projects"
            :key="index"
          >
            <div class="cont-label-elem">
              <div class="inner-label-elem">
                <div class="label-bg-date">
                  {{bg.dates.end? new Date(bg.dates.end).getFullYear():'Today' }}
                  <div class="separ"></div>
                  {{bg.dates.start? new Date(bg.dates.start).getFullYear(): '2020' }}
                </div>
                <div class="elem">
                  <h4>{{bg.title}}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import technoList from '@/components/TalentComponents/talentTechnos/talentTechnoList';

export default {
  name: 'TalentSignupProgress',
  data() {
    return {
      heightTagsOK: false,
      heightTechnoOK: false,

    };
  },
  computed: {
    userData() {
      return this.$store.getters['user/userData'];
    },
    info() {
      return this.$store.getters['talent/info'];
    },
    description() {
      return this.$store.state.talent.talentInfo.description || false;
    },
    skills() {
      const talentSkills = this.$store.getters['talent/skills'];
      if (talentSkills && talentSkills.length > 0) {
        return talentSkills;
      }
      return false;
    },
    technologies() {
      const talentTechnologies = this.$store.getters['talent/technologies'];
      if (talentTechnologies && talentTechnologies.length > 0) {
        return talentTechnologies;
      }
      return false;
    },
    socials() {
      const talentSocials = this.$store.getters['talent/socials'];
      if (talentSocials && talentSocials.length > 0) {
        return talentSocials;
      }
      return false;
    },
    professionalBG() {
      const talentProfessionalBG = this.$store.getters['talent/professionalBG'].filter((entry) => !entry.blank);
      if (talentProfessionalBG && talentProfessionalBG.length > 0) {
        return talentProfessionalBG;
      }
      return false;
    },
    projects() {
      const talentProjects = this.$store.getters['talent/projects'].filter((entry) => !entry.blank);
      if (talentProjects && talentProjects.length > 0) {
        return talentProjects;
      }
      return false;
    },
    academicBG() {
      const talentAcademicBG = this.$store.getters['talent/academicBG'].filter((entry) => !entry.blank);
      if (talentAcademicBG && talentAcademicBG.length > 0) {
        return talentAcademicBG.filter((entry) => !entry.blank);
      }
      return false;
    },
  },
  methods: {
    goToIndex(index) {
      this.$store.dispatch('signupIndex', index);
    },
    findByMatchingProperties(set, properties) {
      return set.filter((entry) => Object.keys(properties).every(
        (key) => entry[key] === properties[key],
      ));
    },
    getPictoTechno(tech) {
      if (this.findByMatchingProperties(technoList, { text: tech }).length > 0) {
        return this.findByMatchingProperties(technoList, { text: tech })[0].picto;
      }
      return false;
    },
    handleOpenPanel(e) {
      const dest = e.target.parentNode;
      if (dest.classList.contains('open')) {
        dest.classList.remove('open');
      } else {
        dest.classList.add('open');
      }
    },
    handleupdateheightTag() {
      setTimeout(() => {
        const vh = window.innerHeight / 100;
        const heightTags = document.getElementById('tagslist').offsetHeight;
        if (heightTags >= (vh * 4)) {
          this.heightTagsOK = true;
        } else {
          this.heightTagsOK = false;
        }
      }, 500);
    },
    handleupdateheightTechno() {
      setTimeout(() => {
        const vh = window.innerHeight / 100;
        const heightTechno = document.getElementById('technolist').offsetHeight;
        if (heightTechno >= (vh * 6)) {
          this.heightTechnoOK = true;
        } else {
          this.heightTechnoOK = false;
        }
      }, 500);
    },
  },
  mounted() {
    this.handleupdateheightTag();
    this.handleupdateheightTechno();
    const psProgress = new PerfectScrollbar('#signup-progress', {
      wheelPropagation: false,
      wheelSpeed: 1,
    });
    setTimeout(() => {
      psProgress.update();
    }, 500);
  },
  updated() {
    this.handleupdateheightTag();
    this.handleupdateheightTechno();
  },
};
</script>

<style>

</style>
