<template>
<div class="elem-social radius-small" :class="this.editing ? 'edit-cont' : ''">
  <div class="cont-label-elem">
    <div class="inner-label-elem">
       <div class="label-social" v-if="this.editing">
        <v-select
          :options="siteOptions"
          v-model="initialValue.site"
          :reduce="name => name"
          label="name"
          placeholder="Site"
          class="unclearable"
        />
      </div>
      <social-picto :site="initialValue.site" v-else />
      <div class="elem" :class="this.editing ? 'hide' : ''">
        {{initialValue.link}}
      </div>
      <div class="cont-edit-elem-btn" :class="!this.editing ? '' : 'onedit'">
        <div class="edit" @click="toggleEdit" v-if="!this.editing">
          <span>edit</span>
        </div>
        <div class="edit delete" @click="this.deleteEntry" v-if="this.editing">
          <span>delete</span>
        </div>
        <div class="edit cancel" @click="cancelEdit" v-if="this.value.site && this.editing">
          <span>cancel</span>
        </div>
        <div class="edit" @click="submitEdit" v-if="this.value.site && this.editing">
          <span>save</span>
        </div>
      </div>
    </div>
    <div class="elem input" v-if="this.editing">
      <input class="v-select" placeholder="https://facebook.com/quasting" v-model="initialValue.link" />
    </div>
  </div>
</div>

</template>

<script>
import SocialPicto from '@/components/utilityPartials/socialPicto.vue';

export default {
  name: 'SocialEntry',
  props: ['value', 'submit', 'propClass', 'deleteEntry'],
  components: {
    SocialPicto,
  },
  data() {
    const startEdit = !this.value.site;
    return {
      editing: startEdit,
      initialValue: this.value,
      siteOptions: ['Facebook', 'Github', 'Gitlab', 'Instagram', 'LinkedIn', 'Twitter', 'YouTube', 'Website'],
    };
  },
  methods: {
    toggleEdit() {
      this.editing = true;
    },
    cancelEdit() {
      this.editing = false;
    },
    submitEdit() {
      if (!this.initialValue.site || this.siteOptions.indexOf(this.initialValue.site) === -1) {
        this.deleteEntry();
      } else {
        this.editing = false;
        this.$emit('input', this.initialValue);
        this.submit();
      }
    },
  },

};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  // .edit{
  //   float: right;
  //   display: flex;
  // }

  .hide{
    opacity: 0;
  }

  .input{
    margin-top: 1vh;
  }
  .selectMenu{

  }
  .social-elem{
    display: flex;
    flex-direction: row;
    align-items: center !important;
    justify-content: space-between;
    .cont-label-elem{
      &.editReactive{
      }
    }
    .cont-edit{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .edit{
        margin-right: 10px;
        &:last-child{
          margin-right: 0;
        }
      }
    }
    .selectMenu{
      .v-select{
        width: auto;

        .vs__dropdown-menu{
          max-height: 5vh;
        }
      }
    }
    .v-select {
      background-color: #ffffff;
    }
  }
  .cont-edit-elem-btn{
    right: 2vh !important;
    &.onedit{
      right: 0 !important;
    }
  }
</style>

<style lang="scss">
.selectMenu{
  .v-select{
    .vs__dropdown-menu{
      max-height: 75px;
    }
  }
}
</style>
