<template>
<div class="cont-signup-step">
  <!-- <div class="head-step-signup"> -->
    <div class="subtitle-signup">Verified account</div>
    <div class="title-signup">Please select type</div>
    <div class="label">
      <p>
        <span>Please tell us if you are representing :</span>
      </p>
    </div>
  <!-- </div> -->
  <div class="cont-choice-account">
    <div
      class="buton-choice-account"
      :class="type === 'Employee' ? 'selectedaccount' : ''"
      @click="handleselection('Employee')"
    >
        <img :src="require('@/assets/signup/signup_select_company.png')">
        <img class="hover" :src="require('@/assets/signup/signup_select_company_selected.png')">
    </div>
    <div class="or">or</div>
    <!-- <div class="or" v-if="!type">or</div> -->
    <!-- <div class="or" v-else><br></div> -->
    <div
      class="buton-choice-account"
      :class="type === 'Talent' ? 'selectedaccount' : ''"
      @click="handleselection('Talent')"
    >
      <img :src="require('@/assets/signup/signup_select_talent.png')">
      <img class="hover" :src="require('@/assets/signup/signup_select_talent_selected.png')">
    </div>
  </div>
  <div class="cont-btn first-selection">
    <div v-if="type && type === 'Employee'" class="btn-green greener radius" @click="submit">
      Sign up as Company
    </div>
    <div v-if="type && type === 'Talent'" class="btn-green greener radius" @click="submit">
      Sign up as Talent
    </div>
  </div>
</div>
</template>

<script>

export default {
  name: 'SelectType',
  data() {
    return {
      type: null,
    };
  },
  methods: {
    submit() {
      this.$api.post('auth/profileType', { profileType: this.type }).then((res) => {
        console.log(res.data);
        this.$store.dispatch('auth/setUserType', res.data.profile);
      });
    },
    handleselection(accounttype) {
      console.log('accounttype : ', accounttype);
      this.type = accounttype;
    },
  },
  mounted() {
    this.$store.dispatch('auth/getTokenInfo').then((token) => {
      if (token.user_metadata && token.user_metadata.type) {
        this.type = token.user_metadata;
        this.submit();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.cont-signup-step{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  .cont-choice-account{
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    // margin: 0 auto;
    margin-top: 7vh;
    margin-bottom: 7vh;
    .buton-choice-account{
      width: 22vh;
      position: relative;
      font-size: 0;
      cursor: pointer;
      img{
        width: 100%;
        transition: all 0.2s;
        &.hover{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          opacity: 0;
        }
      }
      &:hover{
        img{
          &.hover{
            opacity: 1;
          }
        }
      }
      &.selectedaccount{
        img{
          &.hover{
            opacity: 1;
          }
        }
      }
    }
    .or{
      font-size: 2.4vh;
      font-family: 'MontserratBold';
    }
  }
  .cont-btn{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    &.first-selection{
      justify-content: flex-start;
    }
    .btn-green{
      font-size: 1.8vh;
      padding: 1.5vh 3vh;
    }
  }
}
</style>
