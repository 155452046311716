<template>
  <div class="Signup fullwidth">
    <div class="fond-split">
      <div class="left-fond"></div>
      <div class="right-fond"></div>
    </div>
    <div class="header max-width">
      <div class="flex-container">
        <div class="flex-container flex-2-tier reverse">
          <div
            class="elem-flex mhspe flex-overflow-column"
          >
            <div class="cont-logo">
              <img :src="require('@/assets/logo_black.svg')">
            </div>
          </div>
          <div
            class="elem-flex mhspe flex-overflow-column"
          >
            <div @click="handleLogin" class="btn-green radius-small">
              {{this.$store.state.auth.loggedIn? $t("logout") : $t("signin")}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cont-signup max-width">
      <div class="flex-container global-cont">
        <div class="flex-container flex-2-tier reverse">
          <div
            class="elem-flex height-full lateral-signup"
          >
            <div class="cont-title-signup">
              <div class="signup-title-page">
                Sign up
              </div>
              <div class="signup-subtitle-page">
                Few steps to finalize<br>your account
              </div>
              <div class="visual-signup">
                <img :src="require('@/assets/signup/visual_signup.svg')">
              </div>
            </div>
            <div class="cont-marker-evol step-01" :class="signupIndex < 4 ? 'step-02' : ''">
              <div class="cont-progress">
                <div class="evol-progress">
                  <div class="fond-progress"></div>
                </div>
                <div class="fond"></div>
              </div>
              <div class="cont-dots">
                <div class="dot"><span>1</span></div>
                <div class="dot" ><span>2</span></div>
                <div class="dot"><span>3</span></div>
              </div>
            </div>
          </div>
          <div
            class="elem-flex height-full"
          >
            <div class="flex-container flex-2-tier-sdmedium">
              <div
                class="elem-flex"
              >
                <div class="cont-step-signup">
                  <Verify v-if="!verified" :token="this.token" />
                  <div class="inner-right-signup" v-else>
                    <!-- <div class="elem-right" v-if="!type"> -->
                      <SelectType v-if="!type"/>
                    <!-- </div> -->
                    <!-- <div class="elem-right" v-else-if="!profileCreated"> -->
                      <profile-build v-else-if="!profileCreated"/>
                    <!-- </div> -->
                    <!-- <div class="elem-right" v-else-if="type === 'Employee'"> -->
                      <company-signup v-else-if="type === 'Employee'"/>
                    <!-- </div> -->
                    <!-- <div class="elem-right" v-else> -->
                      <talent-signup v-else/>
                    <!-- </div> -->
                  </div>
                </div>
              </div>
              <div
                class="elem-flex"
                v-if="signupIndex != 7"
              >
                <talent-signup-progress v-if="type === 'Talent'" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Verify from '@/components/UniversalComponents/Signup/Verify.vue';
import SelectType from '@/components/UniversalComponents/Signup/SelectType.vue';
import ProfileBuild from '@/components/UniversalComponents/Signup/ProfileBuild.vue';
import TalentSignup from '@/components/UniversalComponents/Signup/TalentSignup.vue';
import CompanySignup from '@/components/UniversalComponents/Signup/CompanySignup.vue';
import TalentSignupProgress from '@/components/TalentComponents/signup/talentSignupProgress.vue';

export default {
  name: 'Signup',
  components: {
    Verify,
    SelectType,
    ProfileBuild,
    TalentSignup,
    CompanySignup,
    TalentSignupProgress,
  },
  props: ['token'],
  computed: {
    ...mapState('auth', {
      verified: (state) => state.verified,
      type: (state) => state.type,
      profileCreated: (state) => state.profileCreated,
    }),
    signupIndex() {
      return this.$store.state.signupIndex;
    },
  },
  methods: {
    handleLogin() {
      this.$store.dispatch('auth/logout');
    },
  },
  mounted() {
    if (this.verified) {
      this.step = 1;
      this.$router.replace('/signup');
    }
    if (this.type) {
      this.step = 2;
    }
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";
  .fond-split{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $red_color;
    .left-fond{
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #f8f8f8;
    }
    .right-fond{
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background-color: #ffffff;
    }
  }
</style>

<style lang="scss">
    @import "@/assets/scss/_variables.scss";
    .home{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    // .Signup{
      // min-height: 92vh;
      // display: flex;
      // flex-direction: column;
      // align-items: flex-start;
      // justify-content: flex-start;
    //   background-color: $logo_color_dark;
    //   .inner-signup{
    //     display: flex;
    //     flex: 1 1 auto;
    //     flex-direction: column;
    //     text-align: left;
    //     color: #ffffff;
    //     height: 100%;
    //     padding-left: 30px;
    //     padding-right: 30px;
    //     padding-bottom: 0;
    //     .head-signup{
    //       .title{
    //         font-size: $titre_fs;
    //         font-family: 'MontserratBlack';
    //       }
    //       border-bottom: 1px solid #ffffff;
    //     }
    //     .content-signup{
    //       display: flex;
    //       flex-direction: row;
    //       flex: 1 1 auto;
    //       flex-wrap: wrap;
    //       .left-signup{
    //         position: relative;
    //         width: calc(25% - 1px);
    //         border-right: 1px solid #ffffff;
    //         display: flex;
    //         flex-direction: row;
    //         flex: 1 1 auto;
          // }
    //       .right-signup{
    //         width: calc(75% - 30px);
    //         position: relative;
    //         padding-left: 30px;
    //         padding-top: 30px;
    //         .inner-right-signup{
    //           .head-right{
    //             .nbStep{
    //               margin-top: 22px;
    //               font-size: $classic_fs;
    //               color: $border_darkgray;
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    // @keyframes heightbar {
    //   from { height: 0; }
    //   to { height: 12.5vh; }
    // }
</style>
<style lang="scss">
  @import "@/assets/scss/_variables.scss";
  .head-step-signup{
    .title{
      margin-top: 1vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      img{
        width: 35px;
        margin-left: 20px;
      }
    }
    .label{
      font-size: $mini_fs;
      color: $border_darkgray;
    }
  }
  .btn-green{
  }
</style>
