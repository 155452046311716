<template>
  <div class="finalresume">
    <div class="subtitle-signup">Last step</div>
    <div class="title-signup">
      Please confirm all your data
    </div>
    <div class="label">
      <p>
        <span>
            Please fill in the details below so that we can know you better.
        </span>
      </p>
    </div>
    <!-- <h2>{{currentFieldTitle}}</h2> -->
    <div class="cont-form" >
      <div class="inner-form-resume" id="finalCont" >
        <talent-signup-progress/>
        <div class="cont-btn double last-step">
            <div class="radius btn-green" @click="goLast">Back</div>
            <div class="radius btn-green" @click="goNext" >
              Confirm my data
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import TalentSignupProgress from '@/components/TalentComponents/signup/talentSignupProgress.vue';

export default {
  name: 'ConfirmResume',
  components: {
    TalentSignupProgress,
  },
  mounted() {
    setTimeout(() => {
      const psSteps = new PerfectScrollbar('#finalCont', {
        wheelPropagation: false,
        wheelSpeed: 1,
      });
      psSteps.update();
    }, 100);
  },
  methods: {
    goNext() {
      this.$store.dispatch('signupIndex', this.$store.state.signupIndex + 1);
    },
    goLast() {
      this.$store.dispatch('signupIndex', this.$store.state.signupIndex - 1);
    },
  },
};
</script>

<style lang="scss">
  .cont-btn{
    .last-step{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .finalresume{
    #finalCont{
      padding-bottom: 0.5vh;
      height: 67.5vh;
      .signup-progress{
        padding-left: 0.5vh;
        padding-right: 0.5vh;
        border: 0;
        .inner_techno_list{
          .elem-techno{
            width: calc((100% - 12vh) / 13) !important;
          }
        }
      }
    }
  }

</style>
