<template>
  <div class="cont-signup-step">
    <div class="subtitle-signup">Background informations</div>
    <!-- sub index 0 -->
    <div class="title-signup" v-if="currentIndex === 0">Please describe yourself</div>
    <div class="label" v-if="currentIndex === 0">
      <p>
        <span>Please fill in the details below so that we can know you better.</span>
      </p>
    </div>

    <!-- Description -->
    <div class="cont-form" v-if="currentIndex === 0">
      <div class="inner-form">
        <div class="cont-elem-profile elem-form">
          <wysiwyg class="radius-small" v-model="description" />
        </div>
      </div>
      <div class="cont-btn">
        <div class="btn-green radius greener" @click="next">{{skipBool? 'Skip': 'Next'}}</div>
        <div class="btn-green radius grayer" @click="back" v-if="currentIndex > 0">Back</div>
      </div>
    </div>

    <!-- sub index 1 -->
    <div class="title-signup" v-if="currentIndex === 1">Please tell us about your skills</div>
    <div class="label" v-if="currentIndex === 1">
      <p>
        <span>Please fil in the details below so that we can know you better.</span>
      </p>
    </div>

    <!-- Skills -->
    <div class="cont-form" v-if="currentIndex === 1">
      <div class="inner-form">
        <div class="cont-elem-profile elem-form">
          <vue-tags-input class="radius-small elemfull"
            v-model="skillVals.tag" :tags="skillVals.tags"
            :placeholder="'eg. Klingon, Crypto mining'"
            @tags-changed="newTags => skillVals.tags = newTags"
          />
          <label>Write then press enter to confirm a skill</label>
        </div>
      </div>
      <div class="cont-btn double">
        <div class="btn-green radius grayer" @click="back" v-if="currentIndex > 0">Back</div>
        <div class="btn-green radius greener" @click="next">{{skipBool? 'Skip': 'Next'}}</div>
      </div>
    </div>

    <!-- sub index 2 -->
    <div class="title-signup" v-if="currentIndex === 2">Please tell us about your technologies</div>
    <div class="label" v-if="currentIndex === 2">
      <p>
        <span>Please fil in the details below so that we can know you better.</span>
      </p>
    </div>

    <!-- Technologies -->
    <div class="cont-form" v-if="currentIndex === 2">
      <div class="inner-form">
        <div class="cont-elem-profile elem-form">
          <vue-tags-input class="radius-small elemfull"
            v-model="technologyVals.tag" :tags="technologyVals.tags"
            :placeholder="'eg. Python, Javascript'"
            @tags-changed="newTags => technologyVals.tags = newTags"
            :autocomplete-items="filteredItems"
          />
          <label>Write then press enter to confirm a skill</label>
        </div>
      </div>
      <div class="cont-btn double">
        <div class="btn-green radius grayer" @click="back" v-if="currentIndex > 0">Back</div>
        <div class="btn-green radius greener" @click="next">{{skipBool? 'Skip': 'Next'}}</div>
      </div>
    </div>

    <!-- sub index 3 -->
    <div class="title-signup" v-if="currentIndex === 3">Please tell us about your website</div>
    <div class="label" v-if="currentIndex === 3">
      <p>
        <span>Please fil in the details below so that we can know you better.</span>
      </p>
    </div>

    <!-- Social -->
    <div class="cont-form" v-if="currentIndex === 3">
      <div class="inner-form">
        <social-entry
          v-for="(social, index) in socials"
          :key="index"
          v-model="socials[index]"
          :submit="submit"
          :deleteEntry="() => deleteEntry(index)"
        />
        <div class="cont-btn full">
          <div class="btn-green radius social" @click="addSite">Add website</div>
        </div>
      </div>
      <div class="cont-btn double">
        <div class="btn-green radius grayer" @click="back" v-if="currentIndex > 0">Back</div>
        <div class="btn-green radius greener" @click="next">{{skipBool? 'Skip': 'Next'}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input';
import SocialEntry from '@/components/UniversalComponents/Settings/_socialEntry.vue';
import technoList from '@/components/TalentComponents/talentTechnos/talentTechnoList';

const parseTagArray = (arr) => arr.map((item) => item.text);
export default {
  name: 'TalentInfo',

  components: {
    VueTagsInput,
    SocialEntry,
  },
  data() {
    return {
      description: '',
      skillVals: {
        tag: '',
        tags: [],
      },
      technologyVals: {
        tag: '',
        tags: [],
      },
      socials: [
      ],

    };
  },
  computed: {
    currentIndex() {
      return this.$store.state.signupIndex;
    },
    skipBool() {
      if (this.currentIndex === 0 && this.description === '') {
        return true;
      }
      if (this.currentIndex === 1 && this.skillVals.tags === []) {
        return true;
      }
      if (this.currentIndex === 2 && this.technologyVals.tags === []) {
        return true;
      }
      if (this.currentIndex === 3 && this.socials === []) {
        return true;
      }
      return false;
    },
    filteredItems() {
      return technoList
        .filter((i) => i.text.toLowerCase().indexOf(this.technologyVals.tag.toLowerCase()) !== -1);
    },
  },
  mounted() {
    const talentInfo = { ...this.$store.state.talent.talentInfo };
    if (talentInfo.description) {
      this.description = talentInfo.description;
    }
    if (talentInfo.skills) {
      this.skillVals.tags = talentInfo.skills.map((foo) => ({ text: foo }));
    }
    if (talentInfo.technologies) {
      this.technologyVals.tags = talentInfo.technologies.map((foo) => ({ text: foo }));
    }
    if (talentInfo.socials) {
      this.socials = talentInfo.socials;
    }
  },
  methods: {
    next() {
      const updateObj = {
        description: this.description,
        skills: parseTagArray(this.skillVals.tags),
        technologies: parseTagArray(this.technologyVals.tags),
        socials: this.socials.filter((social) => social.link !== ''),
      };
      this.$store.dispatch('talent/postTalentInfo', updateObj);
      this.$store.dispatch('signupIndex', this.currentIndex + 1);
    },
    back() {
      this.$store.dispatch('signupIndex', this.currentIndex - 1);
    },
    addSite() {
      this.socials.push({ site: '', link: '' });
    },
    submit(event) {
      const target = event.target.parentNode.parentNode;
      if (!target.classList.contains('editing')) {
        document.querySelectorAll('.editing').forEach((elem) => {
          elem.classList.remove('editing');
        });
      }
      target.classList.toggle('editing');
    },
    deleteEntry(index) {
      this.socials.splice(index, 1);
      this.submit();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.cont-form{
  margin-top: 2vh;
  height: auto;
  overflow: visible;
  .elem-form{
    label{
      margin-top: 0.5vh;
      font-size: $fnt-12;
      font-style: italic;
      color: $font_gray_color;
    }
  }
}

.head-settings{
  pointer-events: none;
  .control-open{
    display: none;
  }
}

.title-step{
  margin-bottom: 20px;
}
.cont-btn-profile{
  margin-top: 20px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  .btn-green{
    margin: 0;
  }
}

.cont-btn{
  &.full{
    align-items: unset;
    justify-content: unset;
    .btn-green{
      width: 100%;
      text-align: center;
    }
  }
}
</style>
