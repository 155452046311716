<template>
  <div>
    <div id="inviteContainer" v-if="invite">
      <!-- <h2> Company Invites:</h2> -->
      <Invite :acceptedInviteCallback="acceptedInviteCallback" @empty-invites="openCompanyForm" />
    </div>
    <div id="createPrompt" v-if="!newCompany && !invite">
      <div class="title-step"> Add your company informations </div>
      <div @click="openCompanyForm" class="btn-green radius">Create New Company</div>
    </div>
    <div id="companyCreateForm" v-if="newCompany">
      <div id="companyName">
        <!-- <label>Name:</label> -->
        <input class="v-select" placeholder="Company name" v-model="companyInfo.name" />
        <div v-if="!sending" @click="submitCompany" class="btn-green radius">Submit</div>
      </div>
    </div>

  </div>
</template>

<script>
import Invite from './Invite.vue';

export default {
  name: 'CompanySignup',
  components: {
    Invite,
  },
  data() {
    return {
      invite: true,
      newCompany: false,
      companyInfo: {
        name: '',
      },
      sending: false,

    };
  },
  methods: {
    openCompanyForm() {
      this.invite = false;
      this.newCompany = true;
    },
    acceptedInviteCallback(data) {
      if (data.accepted) {
        this.$store.dispatch('auth/setUserCompany').then(() => {
          Promise.all([
            this.$store.dispatch('company/getInfo'),
            this.$store.dispatch('user/getUserInfo')]).then(() => {
            this.$router.push('/');
          });
        });
      }
    },
    submitCompany() {
      this.sending = true;
      this.$api.post('employee/company', { ...this.companyInfo }).then((res) => {
        if (res.data.status === 'Created') {
          this.$router.go();
        }
      }).catch(() => this.$router.push('/'));
    },
  },

};
</script>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";
  #createPrompt{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .btn-green{
      margin: 0 auto;
      margin-top: 2vh;
      margin-bottom: 1vh;
    }
  }

  #companyName{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .btn-green{
      margin: 0 auto;
      margin-top: 20px;
    }
  }
</style>
